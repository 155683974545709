import utils from './../utils';
import config from '../config';
import credentialsStore from './credentialsStore';
import queryAPI from './queryAPI';
import authAPI from './authAPI';
import iamAPI from './iamAPI';
import notificationAPI from './notificationAPI';
import cmsAPI from './cmsAPI';
import widgetApi from './widgetsAPI';
import deviceManagementAPI from './deviceManagementAPI';
import coreApi from './coreApi'

class API {
  constructor() {
    this.devManagementUrl = config.deviceManagementApi.baseUrl;
    this.queryApiUrl = config.queryApi.baseUrl;
    this.access_token = null;
    this.refresh_token = null;
    this.projectId = '';
    this.userId = '';
    this.headers = {
      'Content-Type': 'application/json'
    };
    this.isRefreshingToken = false;
    this.isImpersonating = false;
    this.originalToken = null;
  }

  generateResponse = resp => {
    let json = resp.json().catch(() => ({}));
    if (resp.ok) {
      return json;
    }
    return json.then(err => {
      throw err;
    });
  };

  getImpersonatorHeader = () => {
    const token = this.isImpersonating ? this.originalToken : this.access_token;
    return { ...this.headers, Authorization: `Bearer ${token}` };
  };

  //#region QUERY API

  createReport = (projectId, table, data) =>
    queryAPI.createReport(this.headers, this.refreshTokenWhenNeeded, projectId, table, data, this.generateResponse);
  generateReport = (projectId, table, data) =>
    queryAPI.generateReport(this.headers, this.refreshTokenWhenNeeded, projectId, table, data, this.generateResponse);
  getHeatmapData = (device, startTime, endTime, filters) => queryAPI.getHeatmapData(device, startTime, endTime, filters);
  getReports = projectId => queryAPI.getReports(this.headers, this.refreshTokenWhenNeeded, projectId);
  getDashboard = (dashboardName) => queryAPI.getDashboard(dashboardName);
  getCMSData = (projectId, table, data) =>
    queryAPI.getCMSData(this.headers, this.refreshTokenWhenNeeded, projectId, table, data);
  getTables = projectId => queryAPI.getTables(this.headers, this.refreshTokenWhenNeeded, projectId);
  getTableSchema = (projectId, table) =>
    queryAPI.getTableSchema(this.headers, this.refreshTokenWhenNeeded, projectId, table);
  getTableData = (projectId, table, data) =>
    queryAPI.getTableData(this.headers, this.refreshTokenWhenNeeded, projectId, table, data);
  cancelReport = (projectId, table, report) =>
    queryAPI.cancelReport(this.headers, this.refreshTokenWhenNeeded, projectId, table, report);
  pauseReport = (projectId, table, report) =>
    queryAPI.pauseReport(this.headers, this.refreshTokenWhenNeeded, projectId, table, report);
  updateReport = (projectId, report, data) =>
    queryAPI.updateReport(this.headers, this.refreshTokenWhenNeeded, projectId, report, data);
  restartReport = (projectId, table, report) =>
    queryAPI.restartReport(this.headers, this.refreshTokenWhenNeeded, projectId, table, report);

  //#endregion

  //#region--------------------------- DEVICE MANAGEMENT API -------------------------------------

  getDeviceDetails = async (deviceId, projectId) => {
    await this.refreshTokenWhenNeeded();
    const url = `${this.devManagementUrl}/projects/${projectId}/devices/${deviceId}`;
    return fetch(url, {
      headers: this.headers
    }).then(res => this.generateResponse(res));
  };
  
  getSolutions = async () => {
    const url = `${this.devManagementUrl}/solutions?page_size=-1`;
    return fetch(url, {
      headers: this.headers
    }).then(res => this.generateResponse(res));
  };

  getSolutionsConfig = async solutionType => {
    const url = `${this.devManagementUrl}/solutions/${solutionType}/configurations?page_size=-1`;
    return fetch(url, {
      headers: this.headers
    }).then(res => this.generateResponse(res));
  };

  getDevicesFromPreset = async () => {
    const url = `${this.devManagementUrl}/deviceConfigs?page_size=-1`;
    return fetch(url, {
      headers: this.headers
    }).then(res => this.generateResponse(res));
  };

  updateSolutionConfig = async (solutionConfig, fields) => {
    await this.refreshTokenWhenNeeded();
    const fieldsToUpdate = fields.map(field => `update_mask=${field}`).join('&');
    const url = `${this.devManagementUrl}/solutions/${solutionConfig.solutionId}/configurations/${solutionConfig.id}/?${fieldsToUpdate}`;
    return fetch(url, {
      method: 'PUT',
      headers: this.headers,
      body: JSON.stringify(solutionConfig)
    }).then(res => res.json());
  };

  createSolutionConfig = async solutionConfig => {
    await this.refreshTokenWhenNeeded();
    const url = `${this.devManagementUrl}/solutions/${solutionConfig.solutionId}/configurations`;
    return fetch(url, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify(solutionConfig)
    }).then(res => res.json());
  };

  updateProject = async (project, fields) => {
    await this.refreshTokenWhenNeeded();
    const paths = fields.map(field => `update_mask=${field}`).join('&');
    const url = `${this.devManagementUrl}/projects/${project.id}?${paths}`;
    return fetch(url, {
      method: 'PUT',
      headers: this.headers,
      body: JSON.stringify(project)
    }).then(res => res.json());
  };

  createProject = async project => {
    await this.refreshTokenWhenNeeded();
    const url = `${this.devManagementUrl}/projects`;
    return fetch(url, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify(project)
    }).then(res => this.generateResponse(res));
  };

  deleteProject = async (projectId, projectName) => {
    await this.refreshTokenWhenNeeded();
    const url = `${this.devManagementUrl}/projects/${projectId}`;
    return fetch(url, {
      method: 'DELETE',
      headers: this.headers,
      body: JSON.stringify({ name: projectName })
    }).then(res => res.json());
  };

  archiveDevice = async (deviceId, projectId, name) => {
    await this.refreshTokenWhenNeeded();
    const body = JSON.stringify({ name });
    const url = `${this.devManagementUrl}/projects/${projectId}/devices/${deviceId}:archive`;
    return fetch(url, {
      method: 'POST',
      body: body,
      headers: this.headers
    }).then(res => this.generateResponse(res));
  };

  setDevice = async (device, fieldsPath) => {
    await this.refreshTokenWhenNeeded();
    const { baseUrl } = config.deviceManagementApi;
    const paths = fieldsPath.map(path => `update_mask=${path}`).join('&');
    const url = `${baseUrl}/projects/${this.projectId}/devices/${device.id}?${paths}`;

    // TODO: Send device settings fields
    const body = JSON.stringify(device);

    return fetch(url, {
      method: 'PUT',
      headers: this.headers,
      body: body
    }).then(res =>
      res.json().then(resp => {
        return resp;
      })
    );
  };

  setDeviceConfig = async (id, newConfig, fieldsPath) => {
    await this.refreshTokenWhenNeeded();
    const { baseUrl } = config.deviceManagementApi;
    const paths = fieldsPath.map(path => `update_mask=${path}`).join('&');
    const url = `${baseUrl}/projects/${this.projectId}/devices/${id}/configuration?${paths}`;
    const body = JSON.stringify(newConfig);
    return fetch(url, {
      method: 'PUT',
      headers: this.headers,
      body: body
    }).then(res =>
      res.json().then(response => {
        return response;
      })
    );
  };

  moveDevices = async (projectId, data) => {
    await this.refreshTokenWhenNeeded();
    const { baseUrl } = config.deviceManagementApi;
    const url = `${baseUrl}/projects/${projectId}/devices:move`;
    const body = JSON.stringify(data);
    return fetch(url, {
      method: 'POST',
      body: body,
      headers: this.getImpersonatorHeader()
    }).then(res => res.json());
  };

  recreateProvisioningToken = async device => {
    await this.refreshTokenWhenNeeded();
    const { baseUrl } = config.deviceManagementApi;
    const url = `${baseUrl}/projects/${this.projectId}/devices/${device.id}:generateProvisioningToken`;
    return fetch(url, {
      method: 'POST',
      headers: this.headers
    }).then(res => res.json());
  };

  // sendCommand = (projectId, entity, data) => {
  //   const url = `${this.devManagementUrl}/projects/${projectId}/devices${entity}`;
  //   return fetch(url, {
  //     method: 'POST',
  //     body: data,
  //     headers: this.headers
  //   })
  //     .then(res => this.generateResponse(res))
  //     .then(res => {
  //       if (res.status && (res.status === 'failed' || res.status === 'timeout')) {
  //         throw res.answer;
  //       }
  //       return res;
  //     });
  // };

  getLicensesFromProject = project => deviceManagementAPI.getLicensesFromProject(project);
  listLicenses = () => deviceManagementAPI.listLicenses();
  createLicenses = licenses => deviceManagementAPI.createLicenses(licenses);
  updateLicense = (license, fields) => deviceManagementAPI.updateLicense(license, fields);
  getDeviceComments = (deviceId, projectId) => deviceManagementAPI.getDeviceComments(deviceId, projectId);
  createDeviceComment = (deviceId, projectId, comment) => deviceManagementAPI.createDeviceComment(deviceId, projectId, comment);
  deleteDeviceComment = (deviceId, projectId, commentId) => deviceManagementAPI.deleteDeviceComment(deviceId, projectId, commentId);
  setHeatmapImage = (deviceId, imageUrl) => deviceManagementAPI.setHeatmapImage(deviceId, imageUrl);

  //#region--------------------------- AUTH API -------------------------------------

  getUser = (uid, accessToken) => authAPI.getUser(uid, accessToken);
  getAccountDetails = users => authAPI.getAccountDetails(users);
  searchUser = uid => authAPI.getUser(uid, this.access_token);
  signin = (email, password) => authAPI.signin(email, password);
  tokenSignin = refreshToken => authAPI.tokenSignin(refreshToken);
  updateUser = (uid, data) => authAPI.setUser(uid, data, this.headers, this.generateResponse);
  refreshSession = refreshToken => authAPI.refreshSession(refreshToken);
  forgotPassword = email => authAPI.resetPassword(email);
  createUser = data => authAPI.signup(data, this.headers, this.refreshTokenWhenNeeded, this.generateResponse);
  inviteUser = (email, projectId, role) => authAPI.inviteUser(email, projectId, role, this.generateResponse);
  confirmInvite = (token, body) => authAPI.confirmInvite(token, body, this.generateResponse);
  getInviteInfo = token => authAPI.getInviteInfo(token, this.generateResponse);
  getFeatureFlags = () => authAPI.getFeatureFlags();

  impersonateUser = async (userId, token) => {
    if (!token) {
      await this.refreshTokenWhenNeeded();
    }

    const { baseUrl } = config.authApi;
    const url = `${baseUrl}/accounts/${userId}/impersonations`;
    // Prevent refreshToken
    this.isImpersonating = true;
    this.userId = userId;
    this.originalToken = token || this.access_token;

    return fetch(url, {
      method: 'POST',
      headers: {
        ...this.headers,
        Authorization: token ? `Bearer ${token}` : this.headers.Authorization
      }
    }).then(this.generateResponse);
  };

  logout = () => {
    this.access_token = this.refresh_token = null;
    this.isImpersonating = false;
    this.originalToken = null;
    this.userId = '';
    credentialsStore.purge();
    utils.purgeShowMask();
  };

  refreshTokenWhenNeeded = async () => {
    if (!this.access_token || !this.refresh_token || this.isRefreshingToken) {
      return;
    }

    this.isRefreshingToken = true;

    const parsedPayload = credentialsStore.parseJwtPayload(this.access_token);
    const now = Math.floor(new Date().getTime() / 1000);
    const exp = parseInt(parsedPayload.exp);

    if (exp > now) {
      // Token still valid
      this.isRefreshingToken = false;
      return;
    } else {
      // Token has expired, so refresh session
      await this.refreshSession(this.refresh_token).then(async res => {
        // Need to see if user was impersonating, because we need to impersonate again
        if (this.isImpersonating) {
          await this.impersonateUser(this.userId, res.accessToken).then(user => {
            // Update AccessToken on header Authorization
            this.setAccessToken(user.accessToken);
          });
        }
        this.isRefreshingToken = false;
      });
    }
  };

  // Update only accessToken (used when impersonating)
  setAccessToken = newToken => {
    this.access_token = newToken;
    this.headers.Authorization = `Bearer ${newToken}`;
  };

  // Update the accessToken and refreshToken (used by login and refresh session)
  setTokens = ({ accessToken, refreshToken }) => {
    this.refresh_token = refreshToken;
    this.setAccessToken(accessToken);
  };

  //#region--------------------------- IAM API -------------------------------------

  testPermission = (entity, id, permissions) => iamAPI.testPermission(entity, id, permissions);
  getPolicy = resource => iamAPI.getPolicy(resource);
  setPolicy = (resource, body) => iamAPI.setPolicy(resource, body);

  //#region--------------------------- NOTIFICATION API -------------------------------------
  getNotifications = () => notificationAPI.getNotifications();
  getNotificationTypes = () => notificationAPI.getNotificationTypes();
  getNotificationPreferences = () => notificationAPI.getNotificationPreferences();
  setNotificationPreferences = newPreferences => notificationAPI.setNotificationPreferences(newPreferences);
  archiveNotification = (notificationsIds, archive) => notificationAPI.archiveNotification(notificationsIds, archive);
  deleteNotification = notificationsIds => notificationAPI.deleteNotification(notificationsIds);

  //#region--------------------------- CMS API ----------------------------------------------
  listIntegrations = () => cmsAPI.listIntegrations();
  listCredentials = (cmsClient, project) => cmsAPI.listCredentials(cmsClient, project);
  checkCredentials = (cmsClient, credentials, credentialFieldName) =>
    cmsAPI.checkCredentials(cmsClient, credentials, credentialFieldName);
  deleteCredential = (cmsClient, project, credentialId) => cmsAPI.deleteCredential(cmsClient, project, credentialId);
  getMappings = (cmsClient, project, credentialId) => cmsAPI.getMappings(cmsClient, project, credentialId);
  updateCredential = (cmsClient, credential, project, credentialFieldName) =>
    cmsAPI.updateCredential(cmsClient, credential, project, credentialFieldName);
  updateMapping = (cmsClient, mapping) => cmsAPI.updateMapping(cmsClient, mapping);
  deleteMapping = (cmsClient, mapping) => cmsAPI.deleteMapping(cmsClient, mapping);
  // createCredential = (cmsClient, credential, project) => cmsAPI.createCredential(cmsClient, credential, project);
  createMapping = (cmsClient, mapping, credential, project) =>
    cmsAPI.createMapping(cmsClient, mapping, credential, project);

  //#region WIDGET API
  getWidget = (widgetUrl) => widgetApi.getWidget(widgetUrl);
  //#endregion

  //#region Sitegroup & Project
  getProjects = (page, filter, orderBy) => coreApi.getSiteGroups(page, filter, orderBy);
  getProjectsByCompany = (cid, page, filter, orderBy) => coreApi.getSiteGroupsByCompany(cid,page, filter, orderBy);
  getProject = (cid,pid) => coreApi.getSiteGroup(cid,pid);
  updateProject = (cid, project, fields) => coreApi.updateSiteGroup(cid, project, fields);
  createSiteGroup = (cid, siteGroup) => coreApi.createSiteGroup(cid,siteGroup);
  getVersion = (cid, pid, appName, page, filter) => coreApi.getVersion(cid, pid, appName, page, filter);
  getDatasources = (cid, pid) => coreApi.getDatasources(cid, pid);
  getProjectStats = (cid,pid) => coreApi.getSiteGroupStats(cid, pid);
  //#endregion
  
  //#region Site
  getSites = (cid,pid, page, filter, orderBy) => coreApi.getSites(cid, pid, page, filter, orderBy);
  createSite = (cid,site) => coreApi.createSite(cid,site);
  updateSite = (cid, site, fields) => coreApi.updateSite(cid, site, fields);
  deleteSite = (cid, pid,sid) => coreApi.deleteSite(cid, pid,sid);
  getSiteTypes = (page, filter, orderBy) => coreApi.getSiteTypes(page, filter, orderBy);
  updateAttachedDevices = (cid, site) => coreApi.updateAttachedDevices(cid, site);
  //#endregion

  //#region Company & Template
  getCompany = (cid) => coreApi.getCompany(cid);
  getCompanies = (page, filter, orderBy) => coreApi.getCompanies(page, filter, orderBy);
  getTemplates = (page, filter) => coreApi.getTemplates(page, filter);
  //#endregion

  //#region Integrations
  getIntegrations = (page, filter, orderBy) => coreApi.getIntegrations(page, filter, orderBy);
  //#endregion

  //#region Credentials
  createCredential = (cid, credential) => coreApi.createCredential(cid, credential);
  //#endregion

  //#region Devices
  getDevices = (cid, page, filter, pageSize, orderBy) => coreApi.getDevices(cid, page, filter, pageSize, orderBy);
  getDeviceConfig = (cid, did) => coreApi.getDeviceConfig(cid, did, this.getImpersonatorHeader());
  sendCommand = (cid, did, command) => coreApi.sendCommand(cid, did, command);
  archiveDevice = (cid, did) => coreApi.archiveDevice(cid, did);
  dearchiveDevice = (cid, did) => coreApi.dearchiveDevice(cid, did);
  updateDevice = (cid,device,fields) => coreApi.updateDevice(cid,device,fields);
  updateDeviceConfig = (cid,did,configuration,fields) => coreApi.updateDeviceConfig(cid,did,configuration,fields);
  //#endregion
}

export default new API();
